<template>
  <GridInfoContainer
    title="點數連結基本資訊"
    labelWidth="120"
    labelGap="12"
    btn="編輯"
    @btnClick="$router.push({
      name: 'MemberShopPointLinkEdit', params: { id: displayData.id }
    })"
  >
    <template #default="slotData">
      <div class="info-container-item gap-[12px]">
        <p class="info-item-label w-[120px]">圖片</p>
        <img class="w-[90px]" :src="imgSrc(300, displayData.Image)">
      </div>
      <InfoContainerItem :data="slotData" label="開始時間" :value="displayData.startAt" />
      <InfoContainerItem :data="slotData" label="連結名稱" :value="displayData.name" />
      <InfoContainerItem :data="slotData" label="結束時間" :value="displayData.endAt" />
      <InfoContainerItem :data="slotData" label="點數名稱" :value="displayData.pointName" />
      <InfoContainerItem :data="slotData" label="發放點數" :value="displayData.point" />
      <InfoContainerItem :data="slotData" label="連結狀態">
        <Tag :type="linkStatus(displayData.status, 'tagType')">{{ linkStatus(displayData.status, 'label') }}</Tag>
      </InfoContainerItem>
    </template>
  </GridInfoContainer>
</template>

<script>
import { defineComponent, computed } from 'vue'
import GridInfoContainer from '@/components/Container/GridInfoContainer.vue'
import InfoContainerItem from '@/components/Container/InfoContainerItem.vue'
import Tag from '@/components/Tag/Tag.vue'
import { formatDate } from '@/utils/date'
import { pointLinkStatusConfig } from '@/config/point'
import { get, find } from 'lodash'
import { imgSrc } from '@/utils/helper'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberShopPointLinkInfoSection',
  components: { GridInfoContainer, InfoContainerItem, Tag },
  props: {
    pointLink: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { shopPointList } = useShop()
    const displayData = computed(() => {
      const pointLink = get(props, 'pointLink')
      return {
        id: get(pointLink, 'id'),
        name: get(pointLink, 'name'),
        startAt: get(pointLink, 'startAt') ? formatDate(get(pointLink, 'startAt'), 'YYYY-MM-DD HH:mm') : '-',
        endAt: get(pointLink, 'endAt') ? formatDate(get(pointLink, 'endAt'), 'YYYY-MM-DD HH:mm') : '無期限',
        Image: get(pointLink, 'Image') || null,
        point: `${get(pointLink, 'point')} 點`,
        status: get(pointLink, 'status'),
        pointName: get(find(shopPointList.value, { key: get(pointLink, 'shopPointKey') }), 'name'),
      }
    })
    const linkStatus = (status, attr) => {
      return get(pointLinkStatusConfig[status], attr)
    }

    return {
      formatDate,
      imgSrc,
      linkStatus,
      displayData,
    }
  },
})
</script>

<style scoped lang="postcss">
.info-container-item {
  @apply flex items-center;
}

.info-item-label {
  @apply text-normal text-gray-80;
}

.info-item-value {
  @apply text-normal text-gray-80;
  @apply font-medium;
}
</style>
